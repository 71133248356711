import React from 'react';
import { graphql } from 'gatsby';

import {
	BlockText,
	BlockTextHighlight,
	BlockTitleText,
	BlockImageText,
	BlockIntroText,
	BlockImageTextOverlap,
	BlockCarouselTextOverlap,
	BlockImageTextContactOverlap,
	BlockImageTextOverlapPlayful,
	BlockImageTextOverlay,
	BlockHeaderSlider,
	BlockHeaderImage,
	BlockMaps,
	BlockFeaturedSlider,
	BlockTextColumnSlider,
	BlockBlocksSlider,
	BlockResultSlider,
	BlockLogoSlider,
	BlockReviewSlider,
	BlockPersons,
	BlockJobOfferSlider,
	BlockTextBlocksManual,
	BlockFeaturedBlocks,
	BlockTextColumn,
	BlockTextIframe,
	BlockContactForm,
	BlockApplicationForm,
	BlockNewsletterForm,
} from './modules'

function getBlockComponent(typename, props) {
	switch (typename) {
	case 'ContentfulBlockText':
		return <BlockText key={props.id} {...props} />
	case 'ContentfulBlockTextHighlight':
		return <BlockTextHighlight key={props.id} {...props} />
	case 'ContentfulBlockTitleText':
		return <BlockTitleText key={props.id} {...props} />
	case 'ContentfulBlockImageText':
		return <BlockImageText key={props.id} {...props} />
	case 'ContentfulBlockIntroText':
		return <BlockIntroText key={props.id} {...props} />
	case 'ContentfulBlockImageTextOverlap':
		return <BlockImageTextOverlap key={props.id} {...props} />
	case 'ContentfulBlockCarouselTextOverlap':
		return <BlockCarouselTextOverlap key={props.id} {...props} />
	case 'ContentfulBlockImageTextContactOverlap':
		return <BlockImageTextContactOverlap key={props.id} {...props} />
	case 'ContentfulBlockImageTextOverlapPlayful':
		return <BlockImageTextOverlapPlayful key={props.id} {...props} />
	case 'ContentfulBlockImageTextOverlay':
		return <BlockImageTextOverlay key={props.id} {...props} />
	case 'ContentfulBlockHeaderSlider':
		return <BlockHeaderSlider key={props.id} {...props} />
	case 'ContentfulBlockHeaderImage':
		return <BlockHeaderImage key={props.id} {...props} />
	case 'ContentfulBlockMaps':
		return <BlockMaps key={props.id} {...props} />
	case 'ContentfulBlockFeaturedSlider':
		return <BlockFeaturedSlider key={props.id} {...props} />
	case 'ContentfulBlockTextColumnSlider':
		return <BlockTextColumnSlider key={props.id} {...props} />
	case 'ContentfulBlockBlocksSlider':
		return <BlockBlocksSlider key={props.id} {...props} />
	case 'ContentfulBlockResultSlider':
		return <BlockResultSlider key={props.id} {...props} />
	case 'ContentfulBlockLogoSlider':
		return <BlockLogoSlider key={props.id} {...props} />
	case 'ContentfulBlockReviewSlider':
		return <BlockReviewSlider key={props.id} {...props} />
	case 'ContentfulBlockPersons':
		return <BlockPersons key={props.id} {...props} />
	case 'ContentfulBlockJobOfferSlider':
		return <BlockJobOfferSlider key={props.id} {...props} />
	case 'ContentfulBlockTextBlocksManual':
		return <BlockTextBlocksManual key={props.id} {...props} />
	case 'ContentfulBlockFeaturedBlocks':
		return <BlockFeaturedBlocks key={props.id} {...props} />
	case 'ContentfulBlockTextColumn':
		return <BlockTextColumn key={props.id} {...props} />
	case 'ContentfulBlockTextIframe':
		return <BlockTextIframe key={props.id} {...props} />
	case 'ContentfulBlockContactForm':
		return <BlockContactForm key={props.id} {...props} />
	case 'ContentfulBlockApplicationForm':
		return <BlockApplicationForm key={props.id} {...props} />
	case 'ContentfulBlockNewsletterForm':
		return <BlockNewsletterForm key={props.id} {...props} />
	}
}

export default ({colorSet, blocks}) => (
	<div className={`module-block color-${colorSet}`}>
		{Array.isArray(blocks) ? blocks.map((block) => getBlockComponent(block.__typename, block)) : ''}
	</div>
)

export const query = graphql`
	fragment Blocks on ContentfulBlockContactFormContentfulBlockApplicationFormContentfulBlockNewsletterFormContentfulBlockFeaturedBlocksContentfulBlockTextColumnContentfulBlockFeaturedSliderContentfulBlockTextColumnSliderContentfulBlockBlocksSliderContentfulBlockResultSliderContentfulBlockHeaderImageContentfulBlockMapsContentfulBlockHeaderSliderContentfulBlockTextContentfulBlockTextHighlightContentfulBlockTitleTextContentfulBlockImageTextContentfulBlockImageTextContactOverlapContentfulBlockImageTextOverlapContentfulBlockCarouselTextOverlapContentfulBlockImageTextOverlapPlayfulContentfulBlockImageTextOverlayContentfulBlockJobOfferSliderContentfulBlockLogoSliderContentfulBlockReviewSliderContentfulBlockPersonsContentfulBlockTextBlocksManualContentfulBlockTextIframeUnion {
		__typename
		... on Node {
			... on ContentfulBlockText {
				...DefaultText
			}
			... on ContentfulBlockTextHighlight {
				...TextHighlight
			}
			... on ContentfulBlockTitleText {
				...TitleText
			}
			... on ContentfulBlockImageText {
				...ImageText
			}
			... on ContentfulBlockIntroText {
				...IntroText
			}
			... on ContentfulBlockContactForm {
				...ContactForm
			}
			... on ContentfulBlockApplicationForm {
				...ApplicationForm
			}
			... on ContentfulBlockNewsletterForm {
				...NewsletterForm
			}
			... on ContentfulBlockTextIframe {
				...TextIframe
			}
			... on ContentfulBlockJobOfferSlider {
				...JobOfferSlider
			}
			... on ContentfulBlockImageTextOverlap {
				...ImageTextOverlap
			}
			... on ContentfulBlockCarouselTextOverlap {
				...CarouselTextOverlap
			}
			... on ContentfulBlockImageTextContactOverlap {
				...ImageTextContactOverlap
			}
			... on ContentfulBlockHeaderImage {
				...HeaderImage
			}
			... on ContentfulBlockMaps {
				...Maps
			}
			... on ContentfulBlockTextBlocksManual {
				...TextBlocksManual
			}
			... on ContentfulBlockFeaturedBlocks {
				...FeaturedBlocks
			}
			... on ContentfulBlockTextColumn {
				...TextColumn
			}
			... on ContentfulBlockImageTextOverlapPlayful {
				...ImageTextOverlapPlayful
			}
			... on ContentfulBlockImageTextOverlay {
				...ImageTextOverlay
			}
			... on ContentfulBlockHeaderSlider {
				...HeaderSlider
			}
			... on ContentfulBlockFeaturedSlider {
				...FeaturedSlider
			}
			... on ContentfulBlockTextColumnSlider {
				...TextColumnSlider
			}
			... on ContentfulBlockBlocksSlider {
				...BlocksSlider
			}
			... on ContentfulBlockResultSlider {
				...ResultSlider
			}
			... on ContentfulBlockLogoSlider {
				...LogoSlider
			}
			... on ContentfulBlockReviewSlider {
				...ReviewSlider
			}
			... on ContentfulBlockPersons {
				...Persons
			}
		}
	}
`;