import React from 'react';
import Text from '../../elements/text/text';
import ImageParallax from '../../elements/image-parallax';
import Label from '../../elements/label';
import styles from './imageText.module.scss';
import { Link, graphql } from 'gatsby';

export default ({
	text,
	image,
	buttonTitle,
	buttonUrl,
	labelTitle,
	backgroundColor,
	setting
}) => {
	return (
		<div className={`module ${styles.imageText} color-${backgroundColor} ${labelTitle && styles.labelView}`} labeled={labelTitle ? 'labeled' : ''} setting={setting}>
			<div className={`module-background ${styles.background}`}></div>
			<div className={styles.wrapper}>
				{labelTitle &&
					<Label labelStyle={styles.label} labelTitle={labelTitle} />
				}
				{image ?
					<ImageParallax image={image} imageStyle={styles.blockImage}/> : ''}
					
				{text ? <Text text={text} buttonTitle={buttonTitle} buttonUrl={buttonUrl} contentStyle={styles.content} blockTextStyle={styles.blockText} buttonStyle={styles.button} /> : ''}
			</div>
		</div>
	)
}

export const query = graphql`
	fragment ImageText on ContentfulBlockImageText {
		id
		buttonTitle
		buttonUrl
		setting
		labelTitle
		backgroundColor
		text {
			childMarkdownRemark {
				html
			}
		}
		image {
			title
			description
			fluid(quality: 100, maxWidth: 2500, toFormat: JPG) {
				src
			}
		}
	}
`;