import React from 'react';
import VisibilitySensor from '../VisibilitySensor';
import { Spring } from 'react-spring/renderprops';

function Subtitle({subtitle, isVisible}) {
	return (
		<Spring
			delay={600}
			config={{
				tension: 70,
				mass: 1,
				friction: 26,
				velocity: 0
			}}
			to={{
				opacity: isVisible ? 1 : 0,
				transform: isVisible ? "translateY(0)" : "translateY(100px)"
			}}
		>
			{props =>
				<h2 className='subtitle' style={props}>
					{subtitle}
				</h2>
			}
		</Spring>
	);
}

export default ({
	title,
	subtitle,
	slideContent,
	strokeDasharray
}) => (
	<VisibilitySensor once={false}>
		{({ isVisible }) => (
			<Spring
				delay={300}
				to={{
					opacity: isVisible ? 1 : 0,
					animationPlayState: isVisible ? "running" : "paused"
				}}
			>
				{props =>
					<div className={`slide-content ${slideContent}`} style={props}>
						<svg height="100" width="1000" stroke="#ffffff" stroke-width="1.5">
			
							<text x="0" y="110" fill="none" font-size = "100" stroke-dasharray={strokeDasharray} style={props} >{title}</text>
							
						
		</svg>
						{subtitle ? <Subtitle isVisible={isVisible} subtitle={subtitle} /> : '' }
					</div>
				}
			</Spring>
		)}
	</VisibilitySensor>
)
