import React from 'react';
import VisibilitySensor from '../VisibilitySensor';
import { Spring } from 'react-spring/renderprops';

export default ({
	image,
	imageStyle,
	imageCover
}) => (
	<VisibilitySensor partialVisibility>
		
			{({ isVisible }) => (
				<div className={imageCover}>
			<Spring
				delay={500}
				to={{
					width: isVisible ? "100%" : "0%",
				}}
			>
				{props =>
					
						<div className={`block-image ${imageStyle}`} style={{ ...props }} >
							<img src={image.fluid ? image.fluid.src : ''} alt={image.title} title={image.description} />
						</div>
				}
			</Spring>
			</div>
			)}
		
	</VisibilitySensor>
)