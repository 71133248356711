import React from 'react';
import { Parallax } from 'react-parallax';

export default ({ backgroundStyle }) => (
	<Parallax style={{ position: 'initial'}}
		renderLayer={percentage => (
			<div className={`module-background ${backgroundStyle}`} style={{
				top: percentage * 180,
			}}></div>
		)}
	>
	</Parallax>
)