import React from 'react';
import { Link, graphql } from 'gatsby';
import styles from './textColumn.module.scss';

export default ({ 
	title,
	columnOne,
	columnTwo
}) => (
	<div className={`module ${styles.textColumn}`}>
		<div className={styles.wrapper}>
			<h2>{title}</h2>
			<div className={styles.items}>
				<div className={`${styles.column} ${styles.left}`} dangerouslySetInnerHTML={{
					__html: columnOne.childMarkdownRemark.html,
				}} >
				</div>
				<div className={`${styles.column} ${styles.right}`} dangerouslySetInnerHTML={{
					__html: columnTwo.childMarkdownRemark.html,
				}} >
				</div>
			</div>
		</div>
	</div>
)

export const query = graphql`
	fragment TextColumn on ContentfulBlockTextColumn {
		id
		title
		columnOne {
			childMarkdownRemark {
				html
			}
		}
		columnTwo {
			childMarkdownRemark {
				html
			}
		}
	}
`;