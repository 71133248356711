import React from 'react';
import { Link } from 'gatsby';

export default ({
	buttonUrl,
	buttonTitle,
	buttonStyle
}) => (
	<div className={`button ${buttonStyle}`}>
		<Link to={buttonUrl}>{buttonTitle}</Link>
	</div>
)
