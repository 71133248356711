import React from 'react';
import Button from '../../elements/button';
import styles from './textBlocksManual.module.scss';
import VisibilitySensor from '../../VisibilitySensor';
import { Spring } from 'react-spring/renderprops';
import { Link, graphql } from 'gatsby';

class BlocksManual extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			blocks: [],
			visible: 100,
			error: false
		}
		this.loadMore = this.loadMore.bind(this);
	}

	block() {
		return (
			this.props.blocks.map((i) =>
				<div className={`${styles.item} fade-in`} key={i.id}>
					<div className={styles.title}><h2>{i.title}</h2></div>
					<div className={styles.intro} dangerouslySetInnerHTML={{
						__html: i.text.childMarkdownRemark.html,
					}} >
					</div>
					{i.buttonTitle ? <Button buttonStyle={styles.button} buttonTitle={i.buttonTitle} buttonUrl={i.buttonUrl} /> : ''}
				</div>
			)
		)
	}

	loadMore() {
		this.setState(({visible, blocks}) => {
			return {visible: visible + blocks.length};
		});
	}

	componentDidMount() {
		this.setState({
			blocks: this.block()
		});
	}

	render() {
		const { columns, title } = this.props
		const { blocks } = this.state
		return (
			<div className={`module ${styles.textBlocksManual}`} columns={columns}>
				<div className={styles.wrapper}>
				<div className={styles.titleBlocks}>
				{title ? <svg height="100" width="1000" stroke="#B1B1B1" stroke-width="1" className={styles.textLine}>
					<VisibilitySensor once={false}>
					{({ isVisible }) => (
						<Spring
							delay={400}
							to={{
								animationPlayState: isVisible ? "running" : "paused"
							}}
						>
							{
								props =>
								
									<text x="0" y="90" fill="none" style={props} >{title}</text>
									
								}
						</Spring>
					)}
					</VisibilitySensor>
				</svg> : ''}
				</div>
					<div className={styles.items}>
						{blocks.slice(0, this.state.visible)}
					</div>
					{this.state.visible < blocks.length &&
						<div className={styles.redIcon}><a onClick={this.loadMore}>Meer bekijken</a></div>
					}
				</div>
			</div>
		)
	}
}

export default BlocksManual;

export const query = graphql`
	fragment TextBlocksManual on ContentfulBlockTextBlocksManual {
		id
		title
		columns
		blocks {
			id
			icon {
				title
				description
				file {
					url
				}
			}
			title
			buttonTitle
			buttonUrl
			text {
				childMarkdownRemark {
					html
				}
			}
		}
	}
`;