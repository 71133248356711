import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import styles from './logoSlider.module.scss';
import { Link, graphql } from 'gatsby';

class LogoSlider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentIndex: 1,
			responsive: {
				300: {
					items: 2,
				},
				800: {
					items: 3,
				},
				1024: {
					items: 5
				}
			  },
			galleryItems: this.galleryItems()
		}
	}
 
	slideTo = (i) => this.setState({ currentIndex: i })
	
	onSlideChanged = (e) => this.setState({ currentIndex: e.item })
	
	slideNext = () => this.setState({ currentIndex: this.state.currentIndex + 1 })
	
	slidePrev = () => this.setState({ currentIndex: this.state.currentIndex - 1 })
	
	galleryItems() {
		return (
			this.props.slides.map((i) => <div className={styles.slideItem} key={i.key}><a href={i.link} target="_blank"><img src={i.image.fluid.src} alt={i.image.title} title={i.image.description} /></a></div>)
		)
	  }
	  
	render() {
		const { title } = this.props
		const { 
			galleryItems,
			responsive,
			currentIndex
		} = this.state
		return (
			<div className={`module ${styles.logoSlider}`}>
        		<div className={styles.wrapper}>
					<h2>{title}</h2>
		
				</div>
				<AliceCarousel
					dotsDisabled={true}
					buttonsDisabled={true}
					fadeOutAnimation={true}
					preservePosition={true}
					items={galleryItems}
					responsive={responsive}
					slideToIndex={currentIndex}
					onSlideChanged={this.onSlideChanged}
					autoPlayInterval={2000}
					autoPlay={true}
				/>
			</div>
		)
	}
}

export default LogoSlider;

export const query = graphql`
	fragment LogoSlider on ContentfulBlockLogoSlider {
		id
		title
		slides {
			id
			title
			link
			image {
				title
				description
				fluid(quality: 100, maxWidth: 2500, toFormat: JPG) {
					src
				}
			}
		}
	}
`;