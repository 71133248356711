import React from 'react';
import Text from '../../elements/text/text';
import styles from './text.module.scss';
import { Link, graphql } from 'gatsby';

export default ({
	text,
	buttonTitle,
	buttonUrl,
	backgroundColor,
	title
}) => {
	return (
		<div className={`module ${styles.text} color-${backgroundColor}`}>
			<div className={`module-background ${styles.background}`}></div>
			<div className={styles.lines}>
				<span />
				<span />
			</div>
			<div className={` ${styles.wrapper} ${backgroundColor == 'white' ? styles.whiteBackground : ''} `}>
				
				{text ? <Text textLine={styles.textLine} title={title} text={text} buttonTitle={buttonTitle} buttonUrl={buttonUrl} contentStyle={styles.content} blockTextStyle={styles.blockText} buttonStyle={styles.button} /> : ''}
			</div>
		</div>
	)
}

export const query = graphql`
	fragment DefaultText on ContentfulBlockText {
		id
		buttonTitle
		buttonUrl
		backgroundColor
		title
		text {
			childMarkdownRemark {
				html
			}
		}
	}
`;