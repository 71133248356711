import React from 'react';
import Text from '../../elements/text/text';
import Label from '../../elements/label';
import styles from './introText.module.scss';
import VisibilitySensor from '../../VisibilitySensor';
import { Spring } from 'react-spring/renderprops';
import { Link, graphql } from 'gatsby';

export default ({
	text,
	buttonTitle,
	buttonUrl,
	buttonSecondTitle,
	buttonSecondUrl,
	labelTitle,
	title,
	titleAnimation
}) => {
	return (
		<div id="intro" className={`module ${styles.introText} ${labelTitle && styles.labelView} ${titleAnimation ? styles.animation : ''}`} labeled={labelTitle ? 'labeled' : ''}>
			<div className={`module-background ${styles.background}`}></div>
			<div className={styles.wrapper}>
				{labelTitle &&
					<Label labelStyle={styles.label} labelTitle={labelTitle} />
				}
				<div className={styles.introTextContainer} >

					{titleAnimation ? <svg height="100" width="1000" stroke="#B1B1B1" stroke-width="1" className={styles.textLine}>
						<VisibilitySensor once={false}>
						{({ isVisible }) => (
							<Spring
								delay={400}
								to={{
									animationPlayState: isVisible ? "running" : "paused"
								}}
							>
								{
									props =>
									
										<text x="0" y="90" fill="none" style={props} >{titleAnimation}</text>
										
									}
							</Spring>
						)}
						</VisibilitySensor>
					</svg> : '' }

					<VisibilitySensor partialVisibility>
						{({ isVisible }) => (
							<Spring
								delay={400}
								to={{
									opacity: isVisible ? 1 : 0,
									transform: isVisible ? "translateY(0)" : "translateY(50px)"
								}}
							>
								{
									props =>
										<h1 className={styles.title} style={props}>{title}</h1>}
							</Spring>
						)}
					</VisibilitySensor>
					{text ? <Text text={text} buttonTitle={buttonTitle} buttonUrl={buttonUrl} buttonSecondTitle={buttonSecondTitle} buttonSecondUrl={buttonSecondUrl} contentStyle={styles.content} blockTextStyle={styles.blockText} buttonStyle={styles.button} /> : ''}
				</div>
			</div>
		</div>
	)
}

export const query = graphql`
	fragment IntroText on ContentfulBlockIntroText {
		id
		buttonTitle
		buttonUrl
		buttonSecondTitle
		buttonSecondUrl
		labelTitle
		title
		titleAnimation
		text {
			childMarkdownRemark {
				html
			}
		}
	}
`;