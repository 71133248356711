import React from 'react';
import styles from './newsletterForm.module.scss';
import { Link, graphql } from 'gatsby';

export default () => (
    <div className={`module ${styles.newsletterForm}`}>
        <div className={styles.wrapper}>
            <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="nieuwsbrief" action="/bedankt/">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="nieuwsbrief" />
                <div className={styles.fields}>
                    <label>
                        E-mailadres
                            <input type="email" name="email" id="email" />
                    </label>
                </div>
                <div className={styles.footer}>
                    <div className={styles.privacy}>Wij hechten grote waarde aan jouw privacy en gaan uiterst zorgvuldig met je gegevens om. Met het versturen van je gegevens ga je akkoord met de behandeling van je gegevens volgens ons <a href="https://assets.ctfassets.net/kqmpmnkj1u32/332yXtsPActPZXuHI5gUyr/0239774747cca9ed52fbfa846ff33ef7/Privacy-Statement.pdf" target="_blank">privacy statement</a>.</div>
                    <div className={styles.button} ><button type="submit">Verzenden</button></div>
                </div>
            </form>
        </div>
    </div>
)

export const query = graphql`
	fragment NewsletterForm on ContentfulBlockNewsletterForm {
		id
        title
	}
`;