import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Text from '../../elements/text/text';
import Background from '../../elements/background';
import Lines from '../../elements/lines/lines';
import styles from './carouselTextOverlap.module.scss';
import { Link, graphql } from 'gatsby';

class CarouselTextOverlap extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentIndex: 1,
			responsive: { 1024: { items: 1 }, 0: { items: 1 } },
			galleryItems: this.galleryItems()
		}
	}

	slideTo = (i) => this.setState({ currentIndex: i })

	onSlideChanged = (e) => this.setState({ currentIndex: e.item })

	slideNext = () => this.setState({ currentIndex: this.state.currentIndex + 1 })

	slidePrev = () => this.setState({ currentIndex: this.state.currentIndex - 1 })

	galleryItems() {
		return (
			this.props.images.map((i) => {
				return (
					<div className={styles.slideItem} key={i}><div className={styles.image}>
						
									<div className={styles.slideImage}>
										<img src={i.fluid.src} alt={i.title} title={i.description} />
									</div>
						
								
					</div>
					</div>
				)
				})
			
		)
	}

	render() {
		const { 
			text,
			images,
			title,
			buttonTitle,
			buttonUrl,
			setting,
			backgroundColor
		} = this.props
		const { 
			galleryItems,
			responsive,
			currentIndex
		} = this.state
		return (
			<div
		className={`module ${styles.imageTextOverlap} color-${backgroundColor}`} setting={setting} >
		<Background backgroundStyle={styles.background}/>
		<div className={styles.wrapper}>
			<Lines linesStyle={styles.lines}
				transformFrom={'translateY(-200px)'}
				transformTo={'translateY(0px)'}
			/>
			<div className={styles.imagesCarousel}>
			<AliceCarousel
					dotsDisabled={true}
					buttonsDisabled={false}
					fadeOutAnimation={true}
					preservePosition={true}
					items={galleryItems}
					responsive={responsive}
					slideToIndex={currentIndex}
					mouseTrackingEnabled={false}
					onSlideChanged={this.onSlideChanged}
					autoPlayInterval={2000}
					autoPlay={false}
				/>
				</div>
				
			{text ? <Text text={text} title={title} textLine={styles.textLine} buttonTitle={buttonTitle} buttonUrl={buttonUrl} blockTextStyle={styles.blockText} buttonStyle={styles.button} contentStyle={styles.content} /> : ''}

		</div>
	</div>
		)
	}
}

export default CarouselTextOverlap;

export const query = graphql`
	fragment CarouselTextOverlap on ContentfulBlockCarouselTextOverlap {
		id
		buttonTitle
		buttonUrl
		setting
		backgroundColor
		title
		text {
			childMarkdownRemark {
				html
			}
		}
		images {
			title
			description
			fluid(quality: 100, maxHeight: 1000, toFormat: JPG) {
				src
			}
		}
	}
`;