import React from 'react';
import styles from './persons.module.scss';
import Label from '../../elements/label';
import { Link, graphql } from 'gatsby';
import VisibilitySensor from '../../VisibilitySensor';
import { Spring } from 'react-spring/renderprops';

class Persons extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			persons: [],
			visible: 100,
			error: false
		}
		this.loadMore = this.loadMore.bind(this);
	}

	person() {
		return (
			this.props.persons.map((i) =>
				<div className={`${styles.item} ${styles.fadeItem}`} key={i.id}>
					<div className={styles.videoContent}>
						<div className={styles.info}>
							<div className={styles.title}>
								{i.title}
							</div>
							<div className={styles.jobTitle}>
								{i.jobTitle}
							</div>
						</div>
					</div>
					<video className={styles.videoTag} autoPlay loop muted>
						<source src={i.video.file.url} type='video/mp4' />
					</video>
				</div>
			)
		)
	}

	loadMore() {
		this.setState(({visible, persons}) => {
			return {visible: visible + persons.length};
		});
	}

	componentDidMount() {
		this.setState({
			persons: this.shuffle(this.person())
		});
	}

	shuffle(array){
		let i = array.length - 1;
		for (; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			const temp = array[i];
			array[i] = array[j];
			array[j] = temp;
		}
		return array;
	}

	render() {
		const { title, text, labelTitle, label } = this.props
		const { persons } = this.state
		return (
			<div id={label ? label : ''} className={`module ${styles.persons}`}>
				<div className={styles.wrapper}>
					<svg height="100" width="1000" stroke="#B1B1B1" stroke-width="1.5" className={styles.textLine}>
						<VisibilitySensor once={false}>
						{({ isVisible }) => (
							<Spring
								delay={400}
								to={{
									animationPlayState: isVisible ? "running" : "paused"
								}}
							>
								{
									props =>
									
										<text x="0" y="90" fill="none" font-size = "100" style={props} >{title}</text>
										
									}
							</Spring>
						)}
					</VisibilitySensor>
					</svg>
					<div className={styles.text}
						dangerouslySetInnerHTML={{
							__html: text.childMarkdownRemark.html,
						}} >
					</div>
					<div className={styles.videoItems}>
					{labelTitle ?
					<Label labelStyle={styles.label} labelTitle={labelTitle} />
				: '' } 
						{persons.slice(0, this.state.visible)}
						{this.state.visible < persons.length &&
							<div className='red-icon'><a onClick={this.loadMore}>Meer bekijken</a></div>
						}
					</div>
				</div>
			</div>
		)
	}
}

export default Persons;

export const query = graphql`
	fragment Persons on ContentfulBlockPersons {
		id
		title
		label
		labelTitle
		text {
			childMarkdownRemark {
				html
			}
		}
		persons {
			id
			title
			jobTitle
			video {
				title
				file {
					url
				}
			}
		}
	}
`;