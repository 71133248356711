import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import styles from './textColumnSlider.module.scss';
import { Link, graphql } from 'gatsby';

class TextColumnSlider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentIndex: 1,
			responsive: { 1024: { items: props.slidesInView } },
			galleryItems: this.galleryItems()
		}
	}
 
	slideTo = (i) => this.setState({ currentIndex: i })
	
	onSlideChanged = (e) => this.setState({ currentIndex: e.item })
	
	slideNext = () => this.setState({ currentIndex: this.state.currentIndex + 1 })
	
	slidePrev = () => this.setState({ currentIndex: this.state.currentIndex - 1 })
	
	thumbItem = (item, i) => <span onClick={() => this.slideTo(i)}>0{i + 1}</span>
	
	galleryItems() {
		return (
			
		this.props.slides.map((i) => {
		const totalItems = '0' + this.props.slides.length
		const itemPosition = this.props.slides.indexOf(i)
		
		return (
			<div className={styles.slideItem} color={i.backgroundColor} key={i}><div className={styles.image}>
				<div className={styles.slideImage}>
					<div className={`${styles.slideContent}`}><div className={styles.title}><h2>{i.title}</h2></div><div className={styles.contentText} dangerouslySetInnerHTML={{
                        __html: i.text.childMarkdownRemark.html,
                        }} >
                    </div></div><div className={styles.position}><span>0{parseInt(itemPosition) + 1}</span> / {totalItems}</div>
				</div>
			</div>
			</div>
		)
		}))
	}
 
	render() {
		const { 
			galleryItems,
			responsive,
			currentIndex
		} = this.state
		const { 
			title,
			text,
			notificationNumber
		} = this.props
		return (
			<div className={`module ${styles.textColumnSlider}`} items={this.props.slidesInView}>
			  	<div className={styles.wrapper}>
					<h2>
						{title}
						{notificationNumber &&
						<span className={styles.counter}>{notificationNumber}</span>
						}
					</h2>
				  <div className={styles.content} dangerouslySetInnerHTML={{
                        __html: text.childMarkdownRemark.html,
                        }} >
                    </div>
				</div>
				<AliceCarousel
					dotsDisabled={true}
					buttonsDisabled={false}
					fadeOutAnimation={true}
					preservePosition={true}
					items={galleryItems}
					responsive={responsive}
					slideToIndex={currentIndex}
					onSlideChanged={this.onSlideChanged}
					mouseTrackingEnabled={true}
					autoPlay={false}
					autoPlayInterval={2000}
					autoHeight={true}
				/>
			</div>
		)
	}
}

export default TextColumnSlider;

export const query = graphql`
	fragment TextColumnSlider on ContentfulBlockTextColumnSlider {
		id
		title
		slidesInView
		text {
			childMarkdownRemark {
				html
			}
		}
		slides {
			id
			title
			text {
				childMarkdownRemark {
					html
				}
			}
			backgroundColor
		}
	}
`;