import React from 'react';
import { Link, graphql } from 'gatsby';
import styles from './maps.module.scss';

export default () => {
	return (
		<div className={`module ${styles.maps}`}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2430.878631346313!2d6.487993415503614!3d52.46322544818801!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c7fddfa5a966f5%3A0x59318fec9dc96048!2sStijlgenoten%20%7C%20Fullservice%20Communicatiebureau%20%7C%20Den%20Ham!5e0!3m2!1snl!2snl!4v1641478301344!5m2!1snl!2snl" width="100%" height="500px" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0">
            </iframe>
		</div>
	)
}

export const query = graphql`
	fragment Maps on ContentfulBlockMaps {
		id
	}
`;