import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Button from '../../elements/button';
import styles from './jobOfferSlider.module.scss';
import { Link, graphql } from 'gatsby';

class JobOfferSlider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentIndex: 1,
			responsive: { 1024: { items: 3 }, 0: { items: 1 } },
			galleryItems: this.galleryItems()
		}
	}

	slideTo = (i) => this.setState({ currentIndex: i })

	onSlideChanged = (e) => this.setState({ currentIndex: e.item })

	slideNext = () => this.setState({ currentIndex: this.state.currentIndex + 1 })

	slidePrev = () => this.setState({ currentIndex: this.state.currentIndex - 1 })

	galleryItems() {
		return (
		this.props.slides.map((i) => <div className={styles.slideItem} key={i.key}><div className={styles.title}>{i.title}</div><div className={styles.intro} dangerouslySetInnerHTML={{
		__html: i.intro.childMarkdownRemark.html,
		}} >
		</div>
		<Button buttonStyle={styles.button} buttonTitle={'Lees meer'} buttonUrl={'/werken-bij-stijlgenoten/' + i.slug} /> </div>)
		)
	}

	render() {
		const { title } = this.props
		const { 
			galleryItems,
			responsive,
			currentIndex
		} = this.state

		return (
			<div className={`module ${styles.jobOfferSlider}`}>
        		<div className={styles.wrapper} >
					<h2>{title}</h2>
				</div>
				<AliceCarousel
					dotsDisabled={true}
					buttonsDisabled={false}
					fadeOutAnimation={true}
					preservePosition={true}
					items={galleryItems}
					responsive={responsive}
					slideToIndex={currentIndex}
					mouseTrackingEnabled={true}
					onSlideChanged={this.onSlideChanged}
					autoPlayInterval={2000}
					autoPlay={false}
				/>
			</div>
		)
	}
}

export default JobOfferSlider;

export const query = graphql`
	fragment JobOfferSlider on ContentfulBlockJobOfferSlider {
		id
		title
		slides {
			id
			slug
			title
			intro {
				childMarkdownRemark {
					html
				}
			}
		}
	}
`;