import React from 'react';
import Text from '../../elements/text/text';
import Label from '../../elements/label';
import styles from './textIframe.module.scss';
import { Link, graphql } from 'gatsby';

export default ({
	text,
	buttonTitle,
	buttonUrl,
	labelTitle,
	backgroundColor,
	setting,
	iframeUrl
}) => {
	return (
		<div className={`module ${styles.textIframe} color-${backgroundColor}`} setting={setting}>
			<div className={`module-background ${styles.background}`}></div>
			<div className={styles.wrapper}>
				{labelTitle &&
					<Label labelTitle={labelTitle} />
				}
				{text ? <Text text={text} buttonTitle={buttonTitle} buttonUrl={buttonUrl} buttonStyle={styles.button} contentStyle={styles.content} blockTextStyle={styles.blockText} /> : ''}
				<div className={styles.iframe}>
					<iframe src={iframeUrl.iframeUrl} width="100%" height="100%" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
				</div>
			</div>
		</div>
	)
}

export const query = graphql`
	fragment TextIframe on ContentfulBlockTextIframe {
		id
		buttonTitle
		buttonUrl
		setting
		backgroundColor
		labelTitle
		text {
			childMarkdownRemark {
				html
			}
		}
		iframeUrl {
			iframeUrl
		}
	}
`;