import React from 'react';
import { Parallax } from 'react-parallax';

export default ({
	image,
	imageStyle
}) => (
	<div className={`block-image ${imageStyle}`} >
		<Parallax style={{ position: 'initial' }}
			renderLayer={percentage => (
				<div className={`block-image ${imageStyle}`} style={{
					top: percentage * 180,
				}}>
					<img src={image.fluid ? image.fluid.src : ''} alt={image.title} title={image.description} />
				</div>
			)}
			>
		</Parallax>
	</div>		
) 