import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import styles from './resultSlider.module.scss';
import Label from '../../elements/label';
import VisibilitySensor from '../../VisibilitySensor';
import { Spring } from 'react-spring/renderprops';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

class ResultSlider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentIndex: 1,
			responsive: { 1024: { items: 1 }, 0: { items: 1 } },
			galleryItems: this.galleryItems(),
			change: false
		}
	}

	slideTo = (i) => this.setState({ currentIndex: i })

	onSlideChange = () => this.setState({ change: true })

	onSlideChanged = (e) => this.setState({ currentIndex: e.item, change: false })

	slideNext = () => this.setState({ currentIndex: this.state.currentIndex + 1 })

	slidePrev = () => this.setState({ currentIndex: this.state.currentIndex - 1 })

	galleryItems() {
		return (
		this.props.slides.map((i) => <div className={styles.slideItem} key={i.key}>
			<Link to={i.url} >
				<div className={styles.block}>
					<div className={styles.content}>
						{i.titleAnimation ? 
							<svg height="100" width="1000" stroke="#ffffff" strokeWidth="2" className={styles.textLine}>
							<VisibilitySensor partialVisibility>
							{({ isVisible }) => (
								<Spring
									delay={400}
									to={{
										animationPlayState: isVisible ? "running" : "paused"
									}}
								>
									{
										props =>
										
											<text x="50" y="90" fill="none" fontSize = "100" strokeDasharray={i.strokeDasharray} className={styles.title} style={props} >{i.titleAnimation}</text>
											
										}
								</Spring>
							)}
							</VisibilitySensor>
							</svg>
						 : <div className={styles.title}>{i.title}</div> }
						
						<div className={styles.subtitle}>{i.subTitle}</div>
					</div>
					<div className={styles.slideImage}><img src={i.image.fluid.src} alt={i.image.title} title={i.image.description} /></div>
					<div className={styles.plusIcon}><span>+</span></div>
					
				</div>
			</Link>
		</div>)
		)
	}

	render() {
		const { title, labelTitle } = this.props
		const { 
			galleryItems,
			responsive,
			currentIndex,
			change
		} = this.state
		return (
			<div className={styles.resultSliderContainer}>
			{labelTitle ?
					<Label labelStyle={styles.label} labelTitle={labelTitle} />
				: '' } 
			<div className={`module ${styles.resultSlider}`} setting={'change-' + change}>
				
        		<div className={styles.wrapper} >
				
					
					<h2>{title}</h2>
				</div>
				<AliceCarousel
					dotsDisabled={true}
					buttonsDisabled={false}
					fadeOutAnimation={true}
					preservePosition={true}
					items={galleryItems}
					responsive={responsive}
					slideToIndex={currentIndex}
					mouseTrackingEnabled={false}
					onSlideChanged={this.onSlideChanged}
					onSlideChange={this.onSlideChange}
					autoPlayInterval={2000}
					autoPlay={false}
				/>
			</div>
			</div>
		)
	}
}

export default ResultSlider;

export const query = graphql`
	fragment ResultSlider on ContentfulBlockResultSlider {
		id
		title
		labelTitle
		slides {
			id
			titleAnimation
			strokeDasharray
			title
			subTitle
			url
			text {
				childMarkdownRemark {
					html
				}
			}
			image {
				title
				description
				fluid(quality: 90, maxWidth: 2000, toFormat: JPG) {
					src
				}
			}
		}
	}
`;