import React from 'react';
import styles from './contactForm.module.scss';
import { Link, graphql } from 'gatsby';

export default ({
	text,
	form
}) => (
	<div className={`module ${styles.contactForm}`}>
		<div className={styles.wrapper}>
			<div className={styles.intro}
				dangerouslySetInnerHTML={{
					__html: text.childMarkdownRemark.html,
				}} >
			</div>
			<form method="post" netlify-honeypot="bot-field" data-netlify="true" name={form} action="/bedankt/">
				<input type="hidden" name="bot-field" />
				<input type="hidden" name="form-name" value={form} />
				<div className={styles.fields}>
					<label>
                        Voornaam & achternaam
						<input type="text" name="name" id="name" />
					</label>
					<label>
                        Organisatie
						<input type="text" name="organisatie" id="organisatie" />
					</label>
					<label>
                        E-mailadres
						<input type="email" name="email" id="email" />
					</label>
					<label>
                        Telefoonnummer
						<input type="phone" name="telefoonnummer" id="telefoonnummer" />
					</label>
					<label className='textarea'>
                        Persoonlijk bericht
						<textarea name="message" id="message" rows="6" />
					</label>
				</div>
				<div className={styles.footer}>
					<div className={styles.privacy}>Wij hechten grote waarde aan jouw privacy en gaan uiterst zorgvuldig met je gegevens om. Met het versturen van je gegevens ga je akkoord met de behandeling van je gegevens volgens ons  <a href="https://assets.ctfassets.net/kqmpmnkj1u32/332yXtsPActPZXuHI5gUyr/0239774747cca9ed52fbfa846ff33ef7/Privacy-Statement.pdf" target="_blank">privacy statement</a>.</div>
					<div className={styles.button} ><button type="submit">Verzenden</button></div>
				</div>
			</form>
		</div>
	</div>
)

export const query = graphql`
	fragment ContactForm on ContentfulBlockContactForm {
		id
        title
        form
        text {
            childMarkdownRemark {
                html
            }
        }
	}
`;