import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import styles from './blocksSlider.module.scss';
import { Link, graphql } from 'gatsby';

class BlocksSlider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentIndex: 1,
			responsive: {
				300: {
					items: 1,
				},
				800: {
					items: 2,
				},
				1300: {
					items: 3
				}
			  },
			galleryItems: this.galleryItems()
		}
	}

	slideTo = (i) => this.setState({ currentIndex: i })

	onSlideChanged = (e) => this.setState({ currentIndex: e.item })

	slideNext = () => this.setState({ currentIndex: this.state.currentIndex + 1 })

	slidePrev = () => this.setState({ currentIndex: this.state.currentIndex - 1 })

	galleryItems() {
		return (
		this.props.slides.map((i) => <div className={styles.slideItem} key={i.key}>
			<Link to={i.buttonUrl} >
			<div className={styles.block}>
				<div className={styles.title}>{i.buttonTitle}</div>
				<div className={styles.slideImage}><img src={i.image.fluid.src} alt={i.image.title} title={i.image.description} /></div>
				<div className={styles.plusIcon}><span>+</span></div>
			</div>
			</Link>
		</div>)
		)
	}

	render() {
		const { title } = this.props
		const { 
			galleryItems,
			responsive,
			currentIndex
		} = this.state

		return (
			<div className={`module ${styles.blocksSlider}`}>
        		<div className={styles.wrapper} >
					<h2>{title}</h2>
				</div>
				<AliceCarousel
					dotsDisabled={true}
					buttonsDisabled={false}
					fadeOutAnimation={true}
					preservePosition={true}
					items={galleryItems}
					responsive={responsive}
					slideToIndex={currentIndex}
					mouseTrackingEnabled={true}
					onSlideChanged={this.onSlideChanged}
					autoPlayInterval={2000}
					autoPlay={false}
				/>
			</div>
		)
	}
}

export default BlocksSlider;

export const query = graphql`
	fragment BlocksSlider on ContentfulBlockBlocksSlider {
		id
		title
		slides {
			id
			title
			buttonTitle
			buttonUrl
			image {
				title
				description
				fluid(quality: 100, maxWidth: 2500, toFormat: JPG) {
					src
				}
			}
		}
	}
`;