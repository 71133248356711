import React from 'react';
import styles from './applicationForm.module.scss';
import { Link, graphql } from 'gatsby';

export default ({ namePerson }) => (
	<div className={`module ${styles.applicationForm}`}>
		<div className={styles.wrapper}>
			<form method="post" netlify-honeypot="bot-field" data-netlify="true" name={namePerson ? 'application-' + namePerson : 'application'} action="/bedankt/">
				<input type="hidden" name="bot-field" />
				<input type="hidden" name="form-name" value={namePerson ? 'application-' + namePerson : 'application'} />
				<div className={styles.fields}>
					<label>
                        Voornaam
						<input type="text" name="voornaam" id="voornaam" />
					</label>
					<label>
                        Achternaam
						<input type="text" name="achternaam" id="achternaam" />
					</label>
					<label>
                        E-mailadres
						<input type="email" name="email" id="email" />
					</label>
					<label>
                        Telefoonnummer
						<input type="phone" name="telefoonnummer" id="telefoonnummer" />
					</label>

					<label>
                        Foto <span>Toegestane bestandstypes: JPEG, JPG en PNG</span>
						<input name="foto" type="file" id="foto" />
					</label>

					<label>
                        CV <span>Toegestane bestandstype: PDF, DOCX</span>
						<input name="cv" type="file" id="cv" />
					</label>


					<label>
                        Linkedin url
						<input type="text" name="linkedin" id="linkedin" />
					</label>
					<label>
                        Overige url (videosollicitatie/portfolio)
						<input type="text" name="overigeurl" id="overigeurl" />

					</label>
					<label className='textarea'>
                        Motivatie
						<textarea name="motivatie" id="motivatie" rows="6" />
					</label>
				</div>
				<div className={styles.footer}>
					<div className={styles.privacy}>Wij hechten grote waarde aan jouw privacy en gaan uiterst zorgvuldig met je gegevens om. Met het versturen van je gegevens ga je akkoord met de behandeling van je gegevens volgens ons  <a href="https://assets.ctfassets.net/kqmpmnkj1u32/332yXtsPActPZXuHI5gUyr/0239774747cca9ed52fbfa846ff33ef7/Privacy-Statement.pdf" target="_blank">privacy statement</a>.</div>
					<div className={styles.button} ><button type="submit">Solliciteer</button></div>
				</div>
			</form>
		</div>
	</div>
)

export const query = graphql`
	fragment ApplicationForm on ContentfulBlockApplicationForm {
		id
        title
        namePerson
	}
`;