import React from 'react';
import VisibilitySensor from '../VisibilitySensor';
import { Spring } from 'react-spring/renderprops';

export default ({ backgroundStyle }) => (
	<VisibilitySensor partialVisibility>
		{({ isVisible }) => (
		<Spring
			delay={500}
			to={{
				opacity: isVisible ? 1 : 0,
				transform: isVisible ? "translateX(0)" : "translateX(-100px)"
			}}
		>
			{props =>
				<div className={`module-background ${backgroundStyle}`} style={props}></div>
			}
		</Spring>
		)}
	</VisibilitySensor>
)