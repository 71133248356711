import React from 'react';
import Text from '../../elements/text/text';
import Image from '../../elements/image';
import styles from './imageTextOverlay.module.scss';
import { Link, graphql } from 'gatsby';

export default ({
	text,
	image,
	buttonTitle,
	buttonUrl,
	setting,
	backgroundColor,
}) => (
	<div
		className={`module image-text-overlay ${styles.imageTextOverlay} color-${backgroundColor}`}
		setting={setting}
	>
		<div className={styles.wrapper}>
			{image ?
				<Image image={image} imageStyle={styles.blockImage} /> : ''}
			{text ? <Text
						text={text}
						buttonTitle={buttonTitle}
						buttonUrl={buttonUrl}
						blockTextStyle={styles.blockText}
                        buttonStyle={styles.button}
                        contentStyle={styles.content}/> : ''}
		</div>
	</div>
)

export const query = graphql`
	fragment ImageTextOverlay on ContentfulBlockImageTextOverlay {
		id
		buttonTitle
		buttonUrl
		setting
		backgroundColor
		text {
			childMarkdownRemark {
				html
			}
		}
		image {
			title
			description
			fluid(quality: 100, maxWidth: 2500, toFormat: PNG) {
				src
			}
		}
	}
`;