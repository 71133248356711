import React from 'react';
import Text from '../../elements/text/text';
import Image from '../../elements/image';
import BackgroundParallax from '../../elements/background-parallax';
import Label from '../../elements/label';
import styles from './imageTextOverlapPlayful.module.scss';
import { Link, graphql } from 'gatsby';

export default ({
	text,
	image,
	buttonTitle,
	buttonUrl,
	labelTitle,
	backgroundColor,
	setting,
}) => {
	return (
		<div
			className={`module ${styles.imageTextOverlapPlayful} color-${backgroundColor} ${labelTitle && styles.labelView}}`}
			labeled={labelTitle ? 'labeled' : ''}
			setting={setting}
		>
			<BackgroundParallax backgroundStyle={styles.background} />
			<div className={styles.wrapper}>
				{labelTitle && (
					<Label labelTitle={labelTitle} />
				)}
				{image ?
					<Image image={image} imageStyle={styles.blockImage} /> : ''}
				{text ?
					<Text
						text={text}
						buttonTitle={buttonTitle}
                        buttonUrl={buttonUrl}
                        blockTextStyle={styles.blockText}
                        buttonStyle={styles.button}
                        contentStyle={styles.content}
					/> : ''}
			</div>
		</div>
	)
}

export const query = graphql`
	fragment ImageTextOverlapPlayful on ContentfulBlockImageTextOverlapPlayful {
		id
		labelTitle
		buttonTitle
		buttonUrl
		setting
		backgroundColor
		text {
			childMarkdownRemark {
				html
			}
		}
		image {
			title
			description
			fluid(quality: 100, maxWidth: 2500, toFormat: JPG) {
				src
			}
		}
	}
`;