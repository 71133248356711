import React from 'react';
import Text from '../../elements/text/text';
import styles from './textHighlight.module.scss';
import { Link, graphql } from 'gatsby';
import VisibilitySensor from '../../VisibilitySensor';
import { Spring } from 'react-spring/renderprops';
import Lines from '../../elements/lines/lines';

export default ({
	text,
	title,
	buttonTitle,
	buttonUrl,
	backgroundColor
}) => {
	return (
		<div className={`module ${styles.textHighlight} color-${backgroundColor}`}>
			<div className={`module-background ${styles.background}`}></div>
			<Lines linesStyle={styles.lines}
				transformFrom={'translateY(-200px)'}
				transformTo={'translateY(0px)'}
			/>
			<div className={styles.wrapper}>
			<svg height="100" width="1000" stroke="#ffffff" stroke-width="1.5" className={styles.textLine}>
			<VisibilitySensor once={false}>
			{({ isVisible }) => (
				<Spring
					delay={400}
					to={{
						animationPlayState: isVisible ? "running" : "paused"
					}}
				>
					{
						props =>
						
							<text x="50" y="90" fill="none" font-size = "100" style={props} >{title}</text>
							
						}
				</Spring>
			)}
		</VisibilitySensor>
		</svg>
				{text ? <Text text={text} buttonTitle={buttonTitle} buttonUrl={buttonUrl} contentStyle={styles.content} blockTextStyle={styles.blockText} buttonStyle={styles.button} /> : ''}
			</div>
		</div>
	)
}

export const query = graphql`
	fragment TextHighlight on ContentfulBlockTextHighlight {
		id
		buttonTitle
		buttonUrl
		backgroundColor
		title
		text {
			childMarkdownRemark {
				html
			}
		}
	}
`;