import React from 'react';
import styles from './featuredSlider.module.scss';
import { Link, graphql } from 'gatsby';
import VisibilitySensor from '../../VisibilitySensor';
import { Spring } from 'react-spring/renderprops';
import Button from '../../elements/button';

class FeaturedSlider extends React.Component {
	constructor(props) {
		super(props);
	}
	
	

	render() {
		const { 
			title,
			text,
			notificationNumber,
			buttonTitle,
			buttonUrl
		} = this.props
		return (

			
			<div className={`module ${styles.newsBlocks} ${this.props.slides.length == 2 ?
				'' : styles.space}`}>
				{title ? <div className={styles.wrapper}>
					<h2>
						{title}
					</h2>
				</div> : ''}
				<div className={styles.blocks} >
					{this.props.slides.map((i) => {
						return (
							<div className={styles.slideItem} key={i}><Link to={'/uitgelicht/' + i.slug}><div className={styles.image}>
								<VisibilitySensor partialVisibility>
									{({ isVisible }) => (
										<Spring
										delay={200}
											to={{
												transform: isVisible ? "scale(1)" : "scale(1.7)",
											}}
										>
											{props =>
											<div className={styles.slideImage} style={props}>
												<img src={i.featuredImage.fluid.src} alt={i.featuredImage.title} title={i.featuredImage.description} />
												<div className={styles.plusIcon}><span>+</span></div>
											</div>
								}
										</Spring>
									)}
								</VisibilitySensor>
							</div>
							<div className={styles.slideContent}><div className={styles.tag}>{i.tag}</div><div className={styles.title}><h2>{i.title}</h2></div></div></Link></div>
						)
						})
					}
				</div>
				{this.props.slides.length == 2 ?
					<Button buttonStyle={styles.button} buttonTitle={buttonTitle} buttonUrl={buttonUrl} />
				: ''}
			</div>
		)
	}
}

export default FeaturedSlider;

export const query = graphql`
	fragment FeaturedSlider on ContentfulBlockFeaturedSlider {
		id
		title
		slidesInView
		notificationNumber
		text {
			childMarkdownRemark {
				html
			}
		}
		buttonTitle
		buttonUrl
		slides {
			id
			title
			subtitle
			tag
			slug
			featuredImage {
				title
				description
				fluid(quality: 100, maxWidth: 2500, toFormat: JPG) {
					src
				}
			}
		}
	}
`;