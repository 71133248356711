import React from 'react';
import Text from '../../elements/text/text';
import Image from '../../elements/image';
import Background from '../../elements/background';
import Lines from '../../elements/lines/lines';
import styles from './imageTextOverlap.module.scss';
import { Link, graphql } from 'gatsby';



export default ({
	text,
	image,
	buttonTitle,
	buttonUrl,
	setting,
	backgroundColor,
	video,
	scrollTo
}) => (
	<div
		className={`module ${styles.imageTextOverlap} color-${backgroundColor}`} setting={setting} color={backgroundColor} id={scrollTo}>
		<Background backgroundStyle={styles.background}/>
		<div className={styles.wrapper}>
			<Lines linesStyle={styles.lines}
				transformFrom={'translateY(-200px)'}
				transformTo={'translateY(0px)'}
			/>
			{image ?
				<Image image={image} imageStyle={styles.blockImage} /> : ''}
			{video ?
				<video className={styles.videoTag} controls="controls" loop muted>
				<source src={video.file.url} type='video/mp4' />
			</video>: ''}
			{text ? <Text text={text} buttonTitle={buttonTitle} buttonUrl={buttonUrl} blockTextStyle={styles.blockText} buttonStyle={styles.button} contentStyle={styles.content} /> : ''}

		</div>
	</div>
)

export const query = graphql`
	fragment ImageTextOverlap on ContentfulBlockImageTextOverlap {
		id
		buttonTitle
		buttonUrl
		setting
		backgroundColor
		scrollTo
		video {
          file {
            url
          }
        }
		text {
			childMarkdownRemark {
				html
			}
		}
		image {
			title
			description
			fluid(quality: 90, maxWidth: 1500, toFormat: PNG) {
				src
			}
		}
	}
`;