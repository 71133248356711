import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Text from '../../elements/text/text';
import Background from '../../elements/background';
import Lines from '../../elements/lines/lines';
import styles from './reviewSlider.module.scss';
import { Link, graphql } from 'gatsby';

class ReviewSlider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentIndex: 1,
			responsive: { 1024: { items: 1 }, 0: { items: 1 } },
			galleryItems: this.galleryItems()
		}
	}

	slideTo = (i) => this.setState({ currentIndex: i })

	onSlideChanged = (e) => this.setState({ currentIndex: e.item })

	slideNext = () => this.setState({ currentIndex: this.state.currentIndex + 1 })

	slidePrev = () => this.setState({ currentIndex: this.state.currentIndex - 1 })

	galleryItems() {
		return (
			this.props.slides.map((i) => {
				return (
					<div className={styles.slideItem} key={i}><div className={styles.image}>
						
									<div className={styles.slideImage}>
										<img src={i.image.fluid.src} alt={i.image.title} title={i.image.description} />
									</div>
						
								
					</div>
					{i.text ? <Text text={i.text} blockTextStyle={styles.blockText} contentStyle={styles.content} /> : ''}
					</div>
				)
				})
			
		)
	}

	render() {
		const { 
			setting,
			backgroundColor,
			title
		} = this.props
		const { 
			galleryItems,
			responsive,
			currentIndex
		} = this.state
		return (
			<div>
				<h2 className={styles.title}>{title}</h2>
			
			<div
		className={`module ${styles.imageTextOverlap} color-${backgroundColor}`} setting={setting} >
			
		<Background backgroundStyle={styles.background}/>
		
		<div className={styles.wrapper}>
			<Lines linesStyle={styles.lines}
				transformFrom={'translateY(-200px)'}
				transformTo={'translateY(0px)'}
			/>
			<div className={styles.imagesCarousel}>
			<AliceCarousel
					dotsDisabled={true}
					buttonsDisabled={false}
					fadeOutAnimation={true}
					preservePosition={true}
					items={galleryItems}
					responsive={responsive}
					slideToIndex={currentIndex}
					mouseTrackingEnabled={false}
					onSlideChanged={this.onSlideChanged}
					autoPlayInterval={2000}
					autoPlay={true}
				/>
				</div>
				
			

		</div>
	</div>
	</div>
		)
	}
}

export default ReviewSlider;

export const query = graphql`
	fragment ReviewSlider on ContentfulBlockReviewSlider {
		id
		backgroundColor
		title
		slides {
			text {
				childMarkdownRemark {
					html
				}
			}
			image {
				title
				description
				fluid(quality: 100, maxHeight: 2500, toFormat: JPG) {
					src
				}
			}
		}
		
	}
`;