import React from 'react';
import Image from '../../elements/image';
import Background from '../../elements/background';
import Button from '../../elements/button';
import VisibilitySensor from '../../VisibilitySensor';
import { Spring } from "react-spring/renderprops";
import styles from './imageTextContactOverlap.module.scss';
import { Link, graphql } from 'gatsby';

export default ({
	text,
	image,
	buttonTitle,
	buttonUrl,
	setting,
	backgroundColor,
	phone,
	email
}) => (
	<div className={`module ${styles.imageTextContactOverlap} color-${backgroundColor}`} setting={setting}>
		<Background backgroundStyle={styles.background}/>
	  	<div className={styles.wrapper}>
			{image ?
				<Image image={image} imageStyle={styles.blockImage} /> : ''}
			<div className={styles.blockText} >
				<VisibilitySensor partialVisibility>
					{({ isVisible }) => (
					<Spring
						delay={400}
						to={{
							opacity: isVisible ? 1 : 0,
							transform: isVisible ? "translateY(0)" : "translateY(-50px)"
						}}
					>
						{props =>
								<div className='content' style={{ ...props }}
									dangerouslySetInnerHTML={{
									__html: text.childMarkdownRemark.html,
									}} >
								</div>

						}
					</Spring>
					)}
				</VisibilitySensor>
				<ul className={styles.contact}>
				{phone ? <li className={styles.phone}><a href={'tel:' + phone}>{phone}</a></li> : ''}
				{email ? <li className={styles.email}><a href={'mailto:' + email}>{email}</a></li> : ''}
				</ul>
				{buttonTitle && buttonUrl &&
					<Button buttonTitle={buttonTitle} buttonUrl={buttonUrl} />
				}
			</div>
		</div>
	</div>
)

export const query = graphql`
	fragment ImageTextContactOverlap on ContentfulBlockImageTextContactOverlap {
		id
		buttonTitle
		buttonUrl
		setting
		backgroundColor
		phone
		email
		text {
			childMarkdownRemark {
				html
			}
		}
		image {
			title
			description
			fluid(quality: 100, maxWidth: 2500, toFormat: JPG) {
				src
			}
		}
	}
`;