import React from 'react';
import Button from '../../elements/button';
import styles from './text.module.scss';
import VisibilitySensor from '../../VisibilitySensor';
import { Spring } from 'react-spring/renderprops';

export default ({
	text,
	buttonTitle,
	buttonUrl,
	buttonSecondTitle,
	buttonSecondUrl,
	contentStyle,
	blockTextStyle,
	buttonStyle,
	textLine,
	title
}) => (
	<div className={`block-text ${styles.blockText} ${blockTextStyle}`}>
		{title ?
			<svg height="100" width="1000" stroke="#B1B1B1" stroke-width="1" className={textLine}>
				<VisibilitySensor once={false}>
				{({ isVisible }) => (
					<Spring
						delay={400}
						to={{
							animationPlayState: isVisible ? "running" : "paused"
						}}
					>
						{
							props =>
							
								<text x="0" y="80" fill="none" style={props} >{title}</text>
								
							}
					</Spring>
				)}
				</VisibilitySensor>
			</svg>
			: ''}
		<div className={`${styles.content} ${contentStyle}`}
			dangerouslySetInnerHTML={{
				__html: text.childMarkdownRemark.html,
			}} >
		</div>
		<div className={styles.buttons}>
			{buttonTitle && buttonUrl &&
				<Button buttonTitle={buttonTitle} buttonUrl={buttonUrl} buttonStyle={buttonStyle} />
			}
			{buttonSecondTitle && buttonSecondUrl &&
				<Button buttonTitle={buttonSecondTitle} buttonUrl={buttonSecondUrl} buttonStyle={buttonStyle} />
			}
		</div>
	</div>
)
