import React from 'react';
import styles from './featuredBlocks.module.scss';
import { Link, graphql } from 'gatsby';
import VisibilitySensor from '../../VisibilitySensor';
import { Spring } from 'react-spring/renderprops';
import Img from 'gatsby-image';

class FeaturedBlocks extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			blocks: [],
			visible: 4,
			error: false,
			activeFilter: [],
			filter: "",
		}
		this.loadMore = this.loadMore.bind(this)
	}

	handleChange = event => {
		this.setState({ filter: event.target.value });
	};

	block() {
		return this.props.blocks.map((i) => (
			<div className={`fade-in ${styles.item}`} key={i.id}>
				<Link to={i.url}>
					<div className={styles.image}>
						<Img src={i.image.fluid.src} alt={i.image.title} title={i.image.description} />
						
					</div>
					<div className={styles.textBlock}>
						<div className={styles.title}>{i.title}</div>
						<div className="subtitle">{i.subtitle}</div>
						<div>
							{i.type}
							{i.text ? <div className={styles.text}
								dangerouslySetInnerHTML={{
									__html: i.text.childMarkdownRemark.html,
								}} >
							</div> : ''}
						</div>
					</div>

				</Link>
			</div>
		))
	}

	loadMore() {
		this.setState(({ visible, blocks }) => {
			return { visible: blocks.length }
		})
	}

	componentDidMount() {
		this.setState({
			blocks: this.block(),
		})
	}

	render() {
		const { title, notificationNumber, categorien } = this.props
		const { filter } = this.state;
		const lowercasedFilter = filter.toLowerCase();
		const filteredData = this.props.blocks.filter(item => {
        	return Object.keys(item).some(key =>
				typeof item[key] === "string" && item[key].toLowerCase().includes(lowercasedFilter)
        	);
	  	});
		return (
			<div className={`module ${styles.featuredBlocks}`} >
				<div className={styles.wrapper}>
				
					{/* <h2>
						{title}
						{notificationNumber &&
						<span className={styles.counter}>{notificationNumber}</span>
						}
					</h2> */}
					{categorien ?
					<ul className={styles.filter}>
						<span className={styles.filterTitel}>Verfijn op:</span>
						{this.props.categorien.map((i) => (
							<li key={i}><input type="radio" name="filter" id={i} value={i} onChange={this.handleChange} /><label for={i}>{i}</label></li>
						))}
							<li><input type="radio" name="filter" id="Alles" value="" onChange={this.handleChange} /><label for="Alles">Alles</label></li>
					</ul> : ''}
					
					<div className={styles.items}>
					{filteredData.map(i => (
						<div className={`fade-in ${styles.item}`} key={i.id}>
						<Link to={i.url}>
							<div className={styles.image}>
								<Img fluid={i.image.fluid} alt={i.image.title} title={i.image.description} />
								<div className={styles.content}>
									<div className={styles.categorie}>{i.categorie}</div>
									<svg height="100" width="1000" stroke="#ffffff" stroke-width="1" className={styles.textLine}>
										<VisibilitySensor once={false}>
										{({ isVisible }) => (
											<Spring
												delay={400}
												to={{
													animationPlayState: isVisible ? "running" : "paused"
												}}
											>
												{
													props =>
													
														<text x="50" y="90" fill="none" font-size = "100" stroke-dasharray={i.strokeDasharray} style={props} className={styles.type}>{i.type}</text>
														
													}
											</Spring>
										)}
									</VisibilitySensor>
									</svg>
									{i.title ? <div className={styles.title}>{i.title}</div> : ''}
									<div className={styles.subtitle}>{i.subtitle}</div>
								</div>
								<div className={styles.plusIcon}><span>+</span></div>
							</div>
							{i.text ? <div className={styles.textBlock}>
								<div className={styles.title} dangerouslySetInnerHTML={{
									__html: i.text.childMarkdownRemark.html,
								}} >

								</div>
								
							</div> : ''}
							
						</Link>
					</div>
					))}
					</div>
					
					{/* {this.state.visible < blocks.length && (
						<div className="red-icon">
							<a onClick={this.loadMore}>Meer bekijken</a>
						</div>
					)} */}
				</div>
			</div>
		)
	}
}

export default FeaturedBlocks

export const query = graphql`
	fragment FeaturedBlocks on ContentfulBlockFeaturedBlocks {
		id
		title
		notificationNumber
		categorien
		blocks {
			id
			type
			title
			strokeDasharray
			subtitle
			categorie
			text {
				childMarkdownRemark {
					html
				}
			}
			url
			image {
				title
				description
				fluid(quality: 90, maxWidth: 1800, toFormat: JPG) {
					src
				}
			}
		}
	}
`;
