import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import TextSlider from '../../elements/text-slider';
import styles from './headerSlider.module.scss';
import VisibilitySensor from '../../VisibilitySensor';
import { Spring } from 'react-spring/renderprops';
import { Link, graphql } from 'gatsby';
import { Parallax } from 'react-parallax';
import scrollTo from 'gatsby-plugin-smoothscroll';

class HeaderSlider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentIndex: 0,
			responsive: { 1024: { items: 1 } },
			galleryItems: this.galleryItems(),
			autoPlay: true,
			autoPlayInterval: this.props.autoPlayInterval
		}
		console.log(this);
	}
 
	slideTo = (i) => this.setState({ currentIndex: i })
	
	onSlideChanged = (e) => this.setState({ currentIndex: e.item })
	
	slideNext = () => this.setState({ currentIndex: this.state.currentIndex + 1 })
	
	slidePrev = () => this.setState({ currentIndex: this.state.currentIndex - 1 })
	
	thumbItem = (item, i) => {
		return <span className={this.state.currentIndex == i ? styles.active : ''} onClick={e => this.slideTo(i, e.target)}>0{i + 1}</span>
	}
	
	galleryItems() {
		return (
			this.props.slides.map((i) => <div className={styles.slideItem} key={i.id}><Link to={i.url} >
				<Parallax className={styles.parallax} style={{ position: 'initial'}}
					renderLayer={percentage => (
						<div className={styles.slideImage} style={{
							top: percentage * 130,
							position: "relative",
						}}>
							<img src={i.image.fluid.src} alt={i.image.title} title={i.image.description} />			
						</div>
					)}
				>
				</Parallax>
				{i.titleAnimation ?
				<VisibilitySensor once={false}>
					{({ isVisible }) => (
						<Spring
							delay={300}
							to={{
								animationPlayState: isVisible ? "running" : "paused",
								opacity: isVisible ? 1 : 0
							}}
						>
							{props =>
								<div className={styles.typingContainer}>
								<span className={styles.textLines} style={props}><h1 style={props} className={styles.typingAnimation}>{i.titleAnimation}</h1></span>
							</div>
							}
						</Spring>
					)}
				</VisibilitySensor>
				: ''}
				<TextSlider slideContent={styles.slideContent} title={i.title} strokeDasharray={i.strokeDasharray} classname={styles.textLine} subtitle={i.subtitle} /></Link>
			</div>)
		)
	}
 
	render() {
		const { setting } = this.props
		const { 
			galleryItems,
			responsive,
			currentIndex,
			autoPlay,
			autoPlayInterval,
			title
		} = this.state
		return (
			<div className={`module ${styles.headerSlider}`} setting={setting}>
				<AliceCarousel
					dotsDisabled={true}
					buttonsDisabled={true}
					fadeOutAnimation={true}
					preservePosition={true}
					items={galleryItems}
					title={title}
					responsive={responsive}
					slideToIndex={currentIndex}
					autoPlay={galleryItems.length < 2 ? false : true}
					stopAutoPlayOnHover={false}
					autoPlayInterval={autoPlayInterval}
					onSlideChanged={this.onSlideChanged}
				/>
				<div className={styles.lines}>
				<VisibilitySensor partialVisibility>
			{({ isVisible }) => (
				<Spring
					delay={400}
					config={{
						tension: 70,
						mass: 1,
						friction: 26,
						velocity: 0
					}}
					to={{
						opacity: isVisible ? 1 : 0,
						right: isVisible ? 10 : 180
					}}
				>
					{props =>
							<span style={props} className={styles.one} />}
				</Spring>
				
			)}
		</VisibilitySensor>
		<VisibilitySensor partialVisibility>
			{({ isVisible }) => (
				<Spring
					delay={200}
					config={{
						tension: 70,
						mass: 1,
						friction: 16,
						velocity: 80
					}}
					to={{
						opacity: isVisible ? 1 : 0,
						right: isVisible ? 250 : 400
					}}
				>
					{props =>
							<span style={props} className={styles.two} />}
				</Spring>
				
			)}
		</VisibilitySensor>
				</div>

				{galleryItems.length < 2 ? '' : <div className={styles.sliderNavigation}>{this.props.slides.map(this.thumbItem)} </div>}
					
				<div className={styles.redIcon}>Scroll<a onClick={() => scrollTo('#intro')}></a></div>
			</div>
		)
	}
}

export default HeaderSlider;

export const query = graphql`
	fragment HeaderSlider on ContentfulBlockHeaderSlider {
		id
		title
		autoPlayInterval
		setting
		slides {
			id
			title
			titleAnimation
			strokeDasharray
			subtitle
			image {
				title
				description
				fluid(quality: 90, maxWidth: 2500, toFormat: JPG) {
					src
				}
			}
			url
		}
	}
`;